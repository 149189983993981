<template>
  <div :class="$vuetify.breakpoint.name === 'lg' ? 'pr-7 pl-7' : 'px-8'">
    <Snackbar ref="snackbar" />

    <div class="mt-5">
      <div class="title black--text mb-2">List Pages</div>

      <Breadcrumbs :items="breadcrumbs"/>

      <v-row justify="space-between" class="mt-2">
        <v-col cols="4">
          <div>
            <v-text-field
              solo
              filled
              dense
              flat
              :hide-details="true"
              placeholder="Search Page"
              autocomplete="off"
              v-model="searching"
              :readonly="process.run"
              clearable
              color="#F05326"
              @click:clear="searching = ''"
              v-on:keyup.enter="fetch()"
              prepend-inner-icon="mdi-magnify"
            >
            </v-text-field>
          </div>
        </v-col>
        <v-col cols="4" class="text-right">
          <div class="d-flex float-right">
            <v-btn
              depressed
              color="#F05326"
              class="text-capitalize ml-5 white--text rounded-lg"
              :to="{
                name: 'create-page',
                params: { id: $route.params.id },
              }"
            >
              Add Pages
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div class="mt-3 mb-8">
        <v-skeleton-loader
          class="ma-auto"
          :loading="$store.state.process.run"
          :tile="false"
          transition="scale-transition"
          type="table-tbody"
        >
          <v-data-table
            class="table-list pa-0"
            hide-default-footer
            fixed-header
            :loading="$store.state.process.run || process.run"
            :headers="headers"
            :items="items.list"
            :items-per-page.sync="items.limit"
            :page.sync="items.page"
            :server-items-length="items.total"
            item-key="id"
            :footer-props="{
              itemsPerPageText: 'Data per halaman',
              'items-per-page-options': [5, 10, 20, 50, 100, 200, 350],
            }"
          >
            <template slot="footer" slot-scope="{ props }">
              <Pagination
                @reload="fetch(page)"
                @changeLimit="
                  limit = $event;
                  fetch();
                "
                @changePage="
                  page = $event;
                  fetch($event);
                "
                :model="props"
              />
            </template>

            <template slot="item" slot-scope="props">
              <tr class="mx-3 px-3 box-shadow">
                <td class="text-left white">
                  {{ props.item.title }}
                </td>
                <td class="text-center white">
                  <div
                    class="font-weight-bold text-center" 
                    :class="props.item.is_status ? 'teal--text' : 'red--text'">{{ props.item.is_status ?  'Active' : 'Inactive' }}</div>
                </td>
                <td class="text-center white">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        color="blue"
                        v-bind="attrs"
                        v-on="on"
                        :to="{
                          name: 'update-page',
                          params: { slug: props.item.slug, id: $route.params.id },
                        }"
                      >
                        <v-icon>mdi-pencil-circle-outline</v-icon>
                      </v-btn> 
                    </template>
                    <span>Edit Pages</span> 
                  </v-tooltip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { get, post, put, destroy } from "@/service/Axios";
import Pagination from "@/components/Pagination.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Snackbar from "@/components/Snackbar.vue";
export default {
  components: {
    Pagination,
    Breadcrumbs,
    Snackbar,
  },
  data() {
    return {
      title: "List Users",
      limit: 10,
      page: 1,
      selected: {
        pagination: 0,
      },
      headers: [
        { text: "Page Name", align: "start", value: "name", sortable: false },
        { text: "Status", align: "center", value: "status", sortable: false },
        { text: "Action", align: "center", value: "action", sortable: false },
      ],
      breadcrumbs: [
        {
          text: "List Company",
          disabled: false,
          href: "/company",
        },
        {
          text: "List Pages",
          disabled: true,
          href: "/company/trainer-list/",
        },
      ],
      items: {},
      member: {},
      form: {
        member_id: "",
      },
      searching: "",
      process: {
        run: false,
      },
      dialog: {
        detail: false,
        add_trainer: false,
      },
    };
  },
  props: {
    company_id: String,
  },
  watch: {
    "selected.pagination": function (val) {
      this.fetch(val + 1);
    },
    searching: _.debounce(function (newVal) {
      this.searching = newVal;
      this.fetch();
    }, 500),
  },
  created() {
    this.fetch();
  },
  mounted() {},
  methods: {
    changePage(event) {
      if (event === undefined) {
        this.selected.pagination = 0;
      }
    },
    filter() {
      this.$refs.snackbar.open("#4CAF50", `Mohon Maaf Fitur Belum Tersedia`);
    },
    async fetch(paging) {
      this.process.run = true;
      get(`company/v1/list-page`, {
        params: {
          company_id: this.$route.params.id,
          limit: this.limit,
          page: paging,
          sort: "created_at",
          dir: "asc",
          q: this.searching,
        },
      }).then((response) => {
        let res = response.data;
        this.items = res.data;
        this.process.run = false;
        this.$store.state.process.run = false;
        this.totalData = res.data.total;
      });
    },
    details(item) {
      this.member = item;
      this.dialog.detail = true;
    },
    showDialogAddTrainer() {
      this.$refs.dialog_add_trainer.showDialog();
    },
    showDialogChangePassword(name, id) {
      this.$refs.dialog_change_password.showDialog(name, id);
    },
  },
};
</script>

<style></style>
